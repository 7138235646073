import { IndexTable } from '@shopify/polaris';
import Row from './row';

const Offers = ({ offers, loading }) => {
  const resourceName = {
    singular: 'widget',
    plural: 'widgets',
  };

  const rowsMarkup = [...offers].map((offer, index) => (
    <Row key={offer.id} offer={offer} index={index} />
  ));

  return (
    <IndexTable
      resourceName={resourceName}
      itemCount={10}
      headings={[
        { title: 'Name' },
        { title: 'Displayed at' },
        { title: 'Offer products' },
        { title: 'Views' },
        { title: 'Orders' },
        { title: 'Revenue' },
        { title: 'Type' },
        { title: 'Status' },
        { title: '' },
      ]}
      selectable={false}
      loading={loading}
      lastColumnSticky
    >
      {rowsMarkup}
    </IndexTable>
  );
};

export default Offers;
