import { useCallback, useState } from 'react';
import { CalloutCard } from '@shopify/polaris';
import { useStore, usePlan, useToaster } from '@/hooks';
import { useQuery, useMutation, useApolloClient } from '@apollo/client';
import { GET_PRODUCT_LIST, UPDATE_FEATURE, FEATURES } from '@/query';

const CartCallout = () => {
  const { store } = useStore();
  const apolloClient = useApolloClient();
  const [loading, setLoading] = useState(false);
  const toast = useToaster();

  const { domain } = store || {};

  const { data: featureData, loading: loadingFeatures } = useQuery(FEATURES);
  const [updateFeatures] = useMutation(UPDATE_FEATURE);

  const { plan } = usePlan();

  const handleDismiss = useCallback(() => {
    updateFeatures({
      variables: {
        input: {
          cartAnnouncementDismissed: true,
        },
      },
    });
  }, [updateFeatures]);

  const handlePrimaryAction = useCallback(() => {
    const cb = async () => {
      setLoading(true);
      const res = await apolloClient.query({
        query: GET_PRODUCT_LIST,
        variables: { offset: 0, limit: 1 },
      });
      const products = res?.data?.products || [];

      if (products && products.length) {
        const variantId =
          products
            .flatMap((product) => product.variants)
            .find((variant) => variant?.data.availableForSale)?.data
            .shopifyId || null;

        updateFeatures({
          variables: {
            input: {
              cartAnnouncementClicked: true,
            },
          },
        });

        window.open(
          `https://${domain}/cart/${variantId}:1?storefront=true&preview=cart-open`,
          '_blank'
        );
      } else {
        toast({
          content: 'An error occurred',
          error: true,
        });
      }

      setLoading(false);
    };

    cb();
  }, [apolloClient, domain, updateFeatures]);

  if (
    (plan && plan === 'growth') ||
    loadingFeatures ||
    featureData.store.features?.cartAnnouncementDismissed
  )
    return null;

  return (
    <div style={{ marginBottom: '12px' }}>
      <CalloutCard
        title="Explore the new upsell cart!"
        // illustration="/assets/unboxing.svg"
        primaryAction={{
          content: 'Preview in your store',
          onAction: handlePrimaryAction,
          loading,
        }}
        onDismiss={handleDismiss}
      >
        <p>
          Customize your cart experience with upsells, offers, progress bar and
          boost your average order value.
        </p>
      </CalloutCard>
    </div>
  );
};

export default CartCallout;
