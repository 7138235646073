import { useState } from 'react';
import { useRouter } from 'next/router';
import {
  Page,
  Divider,
  InlineStack,
  Text,
  Link,
  Box,
  Card,
} from '@shopify/polaris';
import dayjs from 'dayjs';
import { PageSpinner } from '@/components/library';
import { ACTIVE_OFFERS } from '@/query';
import { usePaginatedQuery, usePlan, useStore, useSupport } from '@/hooks';
import LangProvider from '@/context/language';
import CartCallout from './cart-callout';
// import Support from './support';
import Offers from './offers';
import OfferFilters from './offers/filters';

// import SubscriptionAlert from './subscription-alert';

const analyticsFilters = {
  startDate: dayjs().subtract(30, 'days').format('YYYY-MM-DD'),
  endDate: dayjs().format('YYYY-MM-DD'),
};

const Home = () => {
  const [loaded, setLoaded] = useState(false);
  const [type, setType] = useState(null);
  const [status, setStatus] = useState(null);
  const [queryValue, setQueryValue] = useState(null);
  const { store } = useStore();
  const [sortBy, setSortBy] = useState(['created_at desc']);
  const router = useRouter();
  const { plan } = usePlan();
  const { startContactSupport } = useSupport();

  const [{ loading, error, data }, pagination] = usePaginatedQuery(
    ACTIVE_OFFERS,
    {
      variables: {
        input: {
          isDashboard: true,
          query: queryValue,
          types:
            type && type.length > 0
              ? type
              : [
                  'bundle',
                  'addon',
                  'post-purchase',
                  'recommendation',
                  'volume',
                  'popup-upsell',
                  'popup-cross-sell',
                  'cart-recommendation',
                  'checkout-upsell',
                  'free-gift',
                ],
          active: status,
          sortBy: sortBy[0],
        },
      },
      onCompleted: (res) => {
        const { offers } = res?.data || {};
        if (!loaded) {
          if ((!offers || offers.length === 0) && !queryValue) {
            router.push('/create?first=true');
          } else {
            setLoaded(true);
          }
        }
      },
    }
  );

  if (!loaded && loading) {
    return <PageSpinner />;
  }

  const { offers } = data?.data || {};

  if (!loaded && (!offers || offers.length === 0)) {
    return null;
  }

  return (
    <LangProvider>
      <Page
        fullWidth
        title="Widgets"
        primaryAction={{
          content: 'Create widget',
          url: '/create',
        }}
      >
        {/* <Metrics filters={analyticsFilters} /> */}
        {/* {isEmbedded ? <SubscriptionAlert /> : null} */}
        <CartCallout />
        {/* <ReviewCallout /> */}
        <Card padding="0">
          <OfferFilters
            type={type}
            setType={setType}
            queryValue={queryValue}
            setQueryValue={setQueryValue}
            status={status}
            setStatus={setStatus}
            sortBy={sortBy}
            setSortBy={setSortBy}
          />
          <Offers offers={offers || []} loading={loading} />
          {pagination?.active && (
            <Box>
              <Divider />
              <InlineStack align="center">
                <Box padding="400">{pagination?.paginationMarkup}</Box>
              </InlineStack>
            </Box>
          )}
        </Card>
        <div style={{ marginTop: '12px' }}>
          <InlineStack align="center">
            <Text variant="bodyMd" as="p" tone="subdued">
              To install widgets on your store, please refer to{' '}
              <Link
                monochrome
                url="https://help.assortion.com/en/articles/7208889-installing-widgets-to-your-store"
                external
              >
                this guide
              </Link>{' '}
              or{' '}
              <Link monochrome onClick={startContactSupport}>
                contact us
              </Link>{' '}
              for assistance
            </Text>
          </InlineStack>
        </div>
        {/* <Support /> */}
      </Page>
    </LangProvider>
  );
};

export default Home;
