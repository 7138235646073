import styled from 'styled-components';
import { TextContainer, Tooltip } from '@shopify/polaris';
import { ProductList, Popover } from '@/components/library';
import { algorithms } from '@/components/recommendations/constants';

const ProductCell = ({ data, products }) => {
  if (!data) {
    return '';
  }

  // console.log(data, '');

  const variants = data?.value
    ?.filter((e) => e.variants)
    .reduce((acc, item) => {
      acc[item.id] = item.variants;
      return acc;
    }, {});

  const { page, algorithm, type, value } = data;

  if (page === 'cart') {
    return <TextContent>Cart page</TextContent>;
  }

  if (algorithm && algorithm !== 'manual') {
    return (
      <TextContent>
        {algorithms.find((e) => e.value === algorithm).label}
      </TextContent>
    );
  }

  if (type === 'product') {
    return (
      <Popover
        content={
          <ProductList
            products={products}
            variants={variants}
            isDeletable={false}
            isOrderable={false}
          />
        }
      >
        <div>
          <TextContainer>
            <WrapperHover>
              <Ellipsis>
                <span>
                  {products.map((e) => e.attributes.title).join(', ')}
                </span>
              </Ellipsis>
              {products.length > 1 && (
                <span
                  style={{ marginLeft: '8px' }}
                >{`(${products.length} products)`}</span>
              )}
            </WrapperHover>
          </TextContainer>
        </div>
      </Popover>
    );
  } else {
    let text = '';
    if (type === 'all') text = 'All products';
    else if (type === 'collection')
      text =
        value.length === 1 ? '1 collection' : `${value.length} collections`;
    else if (type === 'tag') text = `Tags: ${value.join(', ')}`;

    return <TextContent>{text}</TextContent>;
  }
};

const TextContent = ({ children }) => (
  <TextContainer>
    <Wrapper>
      <Ellipsis>
        <span>{children}</span>
      </Ellipsis>
    </Wrapper>
  </TextContainer>
);

const Wrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

const WrapperHover = styled(Wrapper)`
  &:hover {
    cursor: pointer;
  }
`;

const Ellipsis = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export default ProductCell;
